@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.mask1 {
    -webkit-mask-image: url('./img/graph.png');
    mask-image: url('./img/graph.png');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    width: 350px;
}

a {
    @apply text-blue-600 hover:text-blue-800 visited:text-purple-600
 }